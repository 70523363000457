import Image from "next/image";
import styled from "styled-components";
import { useEditorialsQuery } from "../../hooks/Queries/useEditorialsQuery";
import { breakpoints } from "../../hooks/useBreakPoints";
import { palette, pxToRem } from "../../styles/styleUtils";

export const EditorialsWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  .editorials-title {
    font-size: ${pxToRem(26)};
    color: ${palette.blue};
    font-weight: 700;
    margin: 22px 0 16px 0;
  }
`;

export const EditorialsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditorialListItem = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${breakpoints.tablet}) {
    flex-wrap: wrap;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  .editorial {
    &__title {
      width: 50%;
      @media (max-width: ${breakpoints.tablet}) {
        width: 100%;
      }
      &--title {
        background: #000;
        color: #fff;
        padding: 30px 5px;
      }
      &--image {
        position: relative;
        min-height: 250px;
        img {
          object-fit: cover;
        }
      }
    }
    &__content {
      width: 50%;

      font-size: ${pxToRem(20)};
      margin-left: 40px;
      position: relative;
      @media (max-width: ${breakpoints.tablet}) {
        width: 100%;
        margin: 10px 0 0 0;
      }
      &--readmore {
        @media (max-width: ${breakpoints.tablet}) {
          width: 100%;
          position: relative;
          height: 30px;
        }
      }
      a.readmore {
        position: absolute;
        right: 0;
        bottom: 0;
        color: ${palette.blue};
        text-decoration: none;
      }
    }
  }
`;

const Editorials = () => {
  const { data: editorialsData, isLoading, isError } = useEditorialsQuery();

  return (
    <EditorialsWrapper>
      <div className="editorials-title">Fantasy News</div>
      {isLoading && <div className="loading-message">Loading...</div>}{" "}
      {isError && <div className="loading-error">Could not load this data. Please try again</div>}
      {!isLoading && !isError && (
        <EditorialsList>
          {editorialsData?.editorials.map((editorial, i) => {
            return (
              <EditorialListItem key={i}>
                <div className="editorial__title">
                  <div className="editorial__title--image">
                    <Image
                      src={editorial?.image ? editorial.image : ""}
                      layout="fill"
                      alt={editorial?.title ? editorial?.title : ""}
                      unoptimized={true}
                    />
                  </div>
                  <div className="editorial__title--title">{editorial?.title}</div>
                </div>
                <div className="editorial__content">
                  <div className="editorial__content--excerpt">{editorial?.description}</div>
                  <div className="editorial__content--readmore">
                    <a
                      className="readmore"
                      href={editorial?.link ? editorial?.link : ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </EditorialListItem>
            );
          })}
        </EditorialsList>
      )}
    </EditorialsWrapper>
  );
};

export default Editorials;
