import { ScorecardContainer, ScorecardWrapper } from "./Scorecard.styled";
import NineHoles from "./NineHoles";
import { useScorecardQuery } from "../../../../hooks/Queries/useScorecardQuery";
import { useRoster } from "../../../../hooks/useRoster";
import { useTournamentQuery } from "../../../../hooks/Queries/useGame";
import { getRoundPointsTotal, getTotalPar } from "../../../../utils/Scorecard";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakPoints";
import Image from "next/image";
import Slider from "react-slick";
import { useRef } from "react";

interface Props {
  isLanding?: boolean;
}

const ScoreCard = ({ isLanding = false }: Props) => {
  const [mobileShowFront, setMobileShowFront] = useState<boolean>(true);
  const { round } = useRoster();
  const { data: scorecardData } = useScorecardQuery();
  const { data: tournyData } = useTournamentQuery();
  const slider = useRef<Slider>(null);

  const currentCourseId = scorecardData?.tee_times && scorecardData?.tee_times[round - 1]?.course_id;
  const startsBackNine = scorecardData?.tee_times && scorecardData?.tee_times[round - 1]?.start_tee === 10;
  let holes =
    currentCourseId && tournyData
      ? tournyData.courses?.find((course) => course?.course_id === currentCourseId)?.holes
      : tournyData?.courses && tournyData?.courses[0].holes;

  if (!holes) {
    // When there are no holes provided return and empty object type IHoles 1-18
    holes = Array.from(Array(18).keys()).map((a) => {
      return { hole_number: a + 1, par: null, yards: null };
    });
  }

  const firstNineArr = holes?.slice(0, 9);
  const secondNineArr = holes?.slice(9);

  // Sets front and back nine arrays based on starting tee for player
  const frontNine = startsBackNine ? secondNineArr : firstNineArr;
  const backNine = startsBackNine ? firstNineArr : secondNineArr;

  const roundScorecard = scorecardData?.scorecards ? scorecardData?.scorecards[round - 1] : null;

  const frontParTotal = getTotalPar(frontNine);
  const backParTotal = getTotalPar(backNine);
  const parTotalsObj = {
    frontParTotal,
    backParTotal,
    total: backParTotal + frontParTotal,
  };

  const frontPointsTotal = getRoundPointsTotal(roundScorecard, frontNine);
  const backPointsTotal = getRoundPointsTotal(roundScorecard, backNine);

  const pointsTotalObj = {
    frontPointsTotal,
    backPointsTotal,
  };

  const { isRosterMobile } = useBreakpoints();

  const showFedexBonus = tournyData?.is_complete;

  const roundComplete = tournyData?.rounds?.[round - 1]?.state === "official";
  const switchSlide = () => {
    if (mobileShowFront) {
      slider?.current?.slickGoTo(1);
    } else {
      slider?.current?.slickGoTo(0);
    }
  };
  return (
    <ScorecardWrapper className={isLanding ? "landing" : ""}>
      <ScorecardContainer>
        {isRosterMobile ? (
          <Slider
            ref={slider}
            arrows={false}
            dots={false}
            infinite={true}
            adaptiveHeight={true}
            slidesToShow={isRosterMobile ? 1 : 2}
            afterChange={(current) => {
              if ((current === 1 && mobileShowFront) || (current === 0 && !mobileShowFront)) {
                setMobileShowFront(!mobileShowFront);
              }
            }}
          >
            <NineHoles
              holes={frontNine}
              isFrontNine={true}
              startsBackNine={startsBackNine}
              roundScores={roundScorecard}
              parTotalsObj={parTotalsObj}
              pointsTotalObj={pointsTotalObj}
              mobileShowFront={mobileShowFront}
              setMobileShowFront={setMobileShowFront}
              roundComplete={roundComplete}
              switchSlide={switchSlide}
              showOut={roundScorecard?.hole_9_prs !== null}
              showIn={roundScorecard?.hole_18_prs !== null}
            />
            <NineHoles
              holes={backNine}
              isFrontNine={false}
              startsBackNine={startsBackNine}
              roundScores={roundScorecard}
              parTotalsObj={parTotalsObj}
              pointsTotalObj={pointsTotalObj}
              mobileShowFront={mobileShowFront}
              setMobileShowFront={setMobileShowFront}
              roundComplete={roundComplete}
              switchSlide={switchSlide}
              showOut={roundScorecard?.hole_9_prs !== null}
              showIn={roundScorecard?.hole_18_prs !== null}
            />
          </Slider>
        ) : (
          <>
            <NineHoles
              holes={frontNine}
              isFrontNine={true}
              startsBackNine={startsBackNine}
              roundScores={roundScorecard}
              parTotalsObj={parTotalsObj}
              pointsTotalObj={pointsTotalObj}
              mobileShowFront={mobileShowFront}
              setMobileShowFront={setMobileShowFront}
              roundComplete={roundComplete}
              switchSlide={switchSlide}
              showOut={roundScorecard?.hole_9_prs !== null}
              showIn={roundScorecard?.hole_18_prs !== null}
            />
            <NineHoles
              holes={backNine}
              isFrontNine={false}
              startsBackNine={startsBackNine}
              roundScores={roundScorecard}
              parTotalsObj={parTotalsObj}
              pointsTotalObj={pointsTotalObj}
              mobileShowFront={mobileShowFront}
              setMobileShowFront={setMobileShowFront}
              roundComplete={roundComplete}
              switchSlide={switchSlide}
              showOut={roundScorecard?.hole_9_prs !== null}
              showIn={roundScorecard?.hole_18_prs !== null}
            />
          </>
        )}
      </ScorecardContainer>

      <div className="score bottom-row">
        {!isRosterMobile && (
          <>
            <div className="score-container demo eagle" />
            <div className="label">Eagle or Better</div>
            <div className="score-container demo birdie" />
            <div className="label">Birdie</div>
            <div className="score-container demo bogey" />
            <div className="label">Bogey</div>
            <div className="score-container demo double-bogey" />
            <div className="label">Double Bogey +</div>
          </>
        )}
        <div className="bonus-points">
          {showFedexBonus && (
            <div className="fedex-bonus">
              <div className="logo">
                <div className="fedex-logo">
                  <Image src="/images/fedexcup.png" layout="fill" unoptimized={true} alt="Fed Ex Cup" />
                </div>
              </div>
              Bonus: <strong>{scorecardData?.fedex_points || "--"}</strong>
            </div>
          )}
          <div className="round-bonus">
            Rd Bonus: <strong>{roundScorecard?.bonus_points || "--"}</strong>
          </div>
        </div>
      </div>
    </ScorecardWrapper>
  );
};

export default ScoreCard;
