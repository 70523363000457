import { IHole } from "../../../../types/interfaces/TournamentTypes";
import { HolesContainer, ScorecardRow } from "./Scorecard.styled";
import { IScorecardRound } from "../../../../types/interfaces/RosterTypes";
import { getScoreClassName, getScoreForHole } from "../../../../utils/Scorecard";
import { useBreakpoints } from "../../../../hooks/useBreakPoints";
import { Dispatch, SetStateAction } from "react";
import { BackArrowIcon } from "../../../../assets/icons";

interface Props {
  holes?: IHole[] | null;
  isFrontNine?: boolean;
  startsBackNine?: boolean | null;
  roundScores?: IScorecardRound | null;
  parTotalsObj: {
    frontParTotal: number;
    backParTotal: number;
    total: number;
  };
  pointsTotalObj: {
    frontPointsTotal: number | "-";
    backPointsTotal: number | "-";
  };
  mobileShowFront: boolean;
  setMobileShowFront: Dispatch<SetStateAction<boolean>>;
  roundComplete: boolean;
  showIn: boolean;
  showOut: boolean;
  switchSlide: () => void;
}

const NineHoles = ({
  holes,
  isFrontNine,
  startsBackNine,
  roundScores,
  parTotalsObj,
  pointsTotalObj,
  setMobileShowFront,
  mobileShowFront,
  roundComplete,
  showIn,
  showOut,
  switchSlide,
}: Props) => {
  const { isRosterMobile } = useBreakpoints();

  return (
    <HolesContainer>
      <ScorecardRow className="holes">
        {(isRosterMobile || isFrontNine) && <div className="hole label">Hole</div>}
        {holes &&
          holes.map((hole, i) => (
            <div className="hole" key={i}>
              {hole.hole_number}
            </div>
          ))}
        {isFrontNine && !isRosterMobile ? (
          <div className="hole out-in">{startsBackNine ? "IN" : "OUT"}</div>
        ) : (
          <>
            {!isRosterMobile && <div className="hole out-in">{startsBackNine ? "OUT" : "IN"}</div>}
            <div className="hole total label">
              {!isRosterMobile ? "total" : "tot"}
              {isRosterMobile && (
                <button
                  onClick={() => {
                    switchSlide();
                    setMobileShowFront(!mobileShowFront);
                  }}
                  className={`mobile-toggle-holes ${mobileShowFront ? "front" : "back"}`}
                >
                  <BackArrowIcon />
                </button>
              )}
            </div>
          </>
        )}
      </ScorecardRow>
      <ScorecardRow className="par">
        {(isRosterMobile || isFrontNine) && <div className="hole label">Par</div>}
        {holes &&
          holes.map((hole, i) => {
            return (
              <div className="hole" key={i}>
                {hole.par || "-"}
              </div>
            );
          })}
        {isFrontNine && !isRosterMobile ? (
          <div className="hole grey out-in">{parTotalsObj.frontParTotal || "-"}</div>
        ) : (
          <>
            {!isRosterMobile && <div className="hole grey out-in">{parTotalsObj.backParTotal || "-"}</div>}
            <div className="hole total grey">{parTotalsObj.total || "-"}</div>
          </>
        )}
      </ScorecardRow>
      <ScorecardRow className="score">
        {(isRosterMobile || isFrontNine) && <div className="hole label">Score</div>}
        {holes &&
          holes.map((hole, i) => {
            return (
              <div key={i} className="hole">
                <div className={`score-container ${getScoreClassName(hole.hole_number, roundScores)}`}>
                  {getScoreForHole(hole.hole_number, "score", roundScores)}
                </div>
              </div>
            );
          })}
        {isFrontNine && !isRosterMobile ? (
          <div className="hole grey out-in par">
            {!showIn && startsBackNine
              ? "-"
              : !showOut && !startsBackNine
              ? "-"
              : startsBackNine && roundScores
              ? roundScores?.in
              : roundScores?.out || "-"}
          </div>
        ) : (
          <>
            {!isRosterMobile && (
              <div className="hole grey out-in par">
                {!showOut && !isFrontNine && startsBackNine
                  ? "-"
                  : !showIn && !isFrontNine && !startsBackNine
                  ? "-"
                  : startsBackNine && roundScores
                  ? roundScores?.out
                  : roundScores?.in || "-"}
              </div>
            )}
            <div className="hole total grey par">{roundScores && roundComplete ? `${roundScores?.total}` : "-"}</div>
          </>
        )}
      </ScorecardRow>
      <ScorecardRow>
        {(isRosterMobile || isFrontNine) && <div className="hole label">Points</div>}
        {holes &&
          holes.map((hole, i) => {
            return (
              <div className="hole" key={i}>
                {getScoreForHole(hole.hole_number, "prs", roundScores)}
              </div>
            );
          })}
        {isFrontNine && !isRosterMobile ? (
          <div className="hole grey out-in">
            {!showIn && startsBackNine ? "-" : !showOut && !startsBackNine ? "-" : pointsTotalObj.frontPointsTotal}
          </div>
        ) : (
          <>
            {!isRosterMobile && (
              <div className="hole grey out-in">
                {!showOut && !isFrontNine && startsBackNine
                  ? "-"
                  : !showIn && !isFrontNine && !startsBackNine
                  ? "-"
                  : pointsTotalObj.backPointsTotal}
              </div>
            )}
            <div className="hole total grey">{roundScores ? roundScores?.scoring_points : "-"}</div>
          </>
        )}
      </ScorecardRow>
    </HolesContainer>
  );
};

export default NineHoles;
