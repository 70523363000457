import styled from "styled-components";
import { palette, pxToRem } from "../../../../styles/styleUtils";
import { breakpoints } from "../../../../hooks/useBreakPoints";

export const PlayerCardContainer = styled.div`
  background-color: ${palette.white};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 264px;
  width: 147px;
  cursor: pointer;
  .swap-label {
    position: absolute;
    right: 30px;
    top: 5px;
    font-size: 12px;
    color: ${palette.textGrey};
    text-transform: uppercase;
    z-index: 1;
    @media (min-width: ${breakpoints.rosterMobile}) {
      right: 4px;
      top: -12px;
    }
  }
  .icon {
    z-index: 1;
    position: absolute;
    display: flex;
    left: 14px;
    top: 5px;
    &.drag-icon {
      right: 5px;
      left: inherit;
      svg {
        height: 17px;
        width: 17px;
      }
      @media (max-width: ${breakpoints.rosterMobile}) {
        right: 10px;
        top: 8px;
        transform: rotate(90deg);
      }
    }
  }

  &.dragging {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  }
  &.placeholder,
  &.cut {
    opacity: 0.5;
    cursor: default;
  }
  &.selected,
  .active {
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -9px;
    height: 9px;
    width: 0%;
    background-color: ${palette.blue};
    transition: 0.2s ease-in-out;
    @media (max-width: ${breakpoints.rosterMobile}) {
      // Hidden on mobile
      display: none;
    }
  }

  @media (max-width: ${breakpoints.rosterMobile}) {
    width: 100%;
    height: 100%;
  }
`;

export const PlayerDataLanding = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  height: 196px;
  padding: 0 6px;
  @media (max-width: ${breakpoints.rosterMobile}) {
    border-width: 1px;
  }

  .landing-headshot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 121px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      width: 86px;
      height: 86px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      width: 61px;
      height: 61px;
    }
  }

  .landing-pick-name {
    text-align: center;
    margin-top: 115px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      margin-top: 95px;
      font-size: ${pxToRem(12)};
    }
    @media (max-width: ${breakpoints.tablet}) {
      margin-top: 65px;
      font-size: ${pxToRem(12)};
    }
  }
  .outline {
    width: calc(100% - 2px);
    height: 85%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-color: ${palette.borderGrey};
    border-style: solid;
    border-width: 0px 1px 1px 1px;
  }

  &.landing {
    height: 165px;
    width: 120px;
    cursor: pointer;
    position: relative;

    @media (max-width: ${breakpoints.rosterMobile}) {
      height: 125px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      height: 105px;
    }

    @media (max-width: ${breakpoints.mobile}) {
      width: 70px;
    }
    overflow: visible;
    button {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: 9px;
        left: -1px;
        bottom: -8px;
        display: block;
        position: absolute;
        transition: 0.2s background-color ease;
        background-color: transparent;
      }
      &:hover,
      &.active,
      &:active {
        &:after {
          background-color: ${palette.blue};
        }
      }
    }
  }

  @media (max-width: ${breakpoints.rosterMobile}) {
    flex-direction: row;
    height: 109.9px;
    align-items: center;
  }
`;

export const PlayerData = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-color: ${palette.borderGrey};
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  height: 196px;
  padding: 0 6px;
  @media (max-width: ${breakpoints.rosterMobile}) {
    border-width: 0px;
    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 55px);
      height: 100%;
      border-color: ${palette.borderGrey};
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      right: 0;
    }
  }
  .headshot {
    display: flex;
    position: absolute;
    border-radius: 50%;
    height: 121px;
    width: 121px;
    overflow: hidden;
    justify-content: center;
    background-color: ${palette.backgroundGrey};
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: ${breakpoints.rosterMobile}) {
      position: static;
      top: auto;
      left: auto;
      height: 98.7px;
      width: 98.7px;
      transform: translate(0, 0);
    }
  }
  .pick-name {
    text-align: center;
    margin: 66.5px 0 13px 0;
    @media (max-width: ${breakpoints.rosterMobile}) {
      margin: 0 0 8px 0;
    }
  }

  .landing-headshot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      width: 86px;
      height: 86px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      width: 61px;
      height: 61px;
    }
  }

  .landing-pick-name {
    text-align: center;
    margin-top: 90px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      margin-top: 70px;
      font-size: ${pxToRem(12)};
    }
    @media (max-width: ${breakpoints.tablet}) {
      margin-top: 40px;
      font-size: ${pxToRem(12)};
    }
  }

  &.landing {
    height: 140px;
    margin-top: 25px;
    width: 120px;
    cursor: pointer;
    position: relative;
    border-width: 0px 1px 1px 1px;

    @media (max-width: ${breakpoints.rosterMobile}) {
      height: 100px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      height: 80px;
    }

    @media (max-width: ${breakpoints.mobile}) {
      width: 70px;
    }
    overflow: visible;
    button {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: 9px;
        left: -1px;
        bottom: -8px;
        display: block;
        position: absolute;
        transition: 0.2s background-color ease;
        background-color: transparent;
      }
      &:hover,
      &.active,
      &:active {
        &:after {
          background-color: ${palette.blue};
        }
      }
    }
  }

  .usage-row {
    @media (max-width: ${breakpoints.rosterMobile}) {
      position: absolute;
      top: 32px;
      right: 10px;
    }
    & > div {
      justify-content: flex-start;
    }
  }

  .pick-state {
    display: flex;
    text-transform: uppercase;
    color: ${palette.textGrey};
    margin: 4px 0 3px 0;

    & > * {
      margin-right: 11px;
    }
    .pick-points {
      color: ${palette.blue};
      font-size: 17px;
      font-weight: bold;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      margin: 10px 0 10px auto;
      padding: 0 9px 15px 0;
      justify-content: flex-end;
      align-items: baseline;
      flex-direction: row;
      height: 100%;
      align-items: flex-end;
    }
    svg {
      height: 19px;
      width: 19px;
      fill: #8e8e8e;
      padding-top: 2px;
    }
  }

  @media (max-width: ${breakpoints.rosterMobile}) {
    flex-direction: row;
    height: 109.9px;
    align-items: center;
  }
`;

export const PlayerDataRow = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: ${pxToRem(12)};
  color: ${palette.textGrey};
  line-height: 1.2;
  .label {
    width: 30px;
  }
  .points {
    margin-left: 15px;
  }
`;

export const MobileCol = styled.div`
  @media (max-width: ${breakpoints.rosterMobile}) {
    flex-direction: row;
    margin-left: 15px;
  }
`;
