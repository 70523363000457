import styled from "styled-components";
import { breakpoints } from "../../../../hooks/useBreakPoints";
import { palette, pxToRem } from "../../../../styles/styleUtils";

export const ScorecardContainer = styled.div`
  display: flex;
  background-color: ${palette.white};
  height: 275px;
  max-width: 1042px;
  overflow: hidden;
  background-color: ${palette.backgroundGrey};
  .slick-slider {
    width: 100%;
  }
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }
`;

export const ScorecardRow = styled.div`
  display: flex;
  flex: 1 1 100%;
  min-width: 100%;
  max-height: 65px;
  align-items: center;
  font-weight: bold;
  background-color: ${palette.white};
  border-bottom: 5px solid ${palette.backgroundGrey};
  @media (max-width: ${breakpoints.rosterMobile}) {
    font-size: 14px;
  }
  &.holes {
    background-color: ${palette.blue};
    color: ${palette.white};
    border-bottom: 5px transparent;
  }
  .hole {
    min-width: 41px;
    flex: 1 0 auto;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: ${breakpoints.rosterMobile}) {
      min-width: 20px;
    }
    &.label {
      text-align: center;
      min-width: 91px;
      flex: 0 1 auto;
      @media (max-width: ${breakpoints.mobile}) {
        text-align: left;
      }
      @media (max-width: ${breakpoints.rosterMobile}) {
        min-width: 35px;
        padding-left: 5px;
      }
    }
    &.out-in {
      max-width: 41px;
    }
    &.total {
      flex: 0 0 99px;
      max-width: 99px;
      display: flex;
      justify-content: space-evenly;
      .mobile-toggle-holes {
        margin-left: 5px;
        display: flex;
        width: 17px;
        &.front {
          transform: rotate(-180deg);
        }
        &.back {
          transform: rotate(0deg);
        }
        svg {
          margin: auto;
          width: 18px;
          height: 18px;
          z-index: 2;
          path {
            fill: ${palette.white};
          }
        }
      }
      &.grey,
      &.label {
        @media (max-width: ${breakpoints.rosterMobile}) {
          max-width: 40px;
        }
      }
    }
    &.grey {
      display: flex;
      background-color: ${palette.backgroundGrey};
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const HolesContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
`;

export const ScorecardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .bonus-points {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: ${pxToRem(16)};
    @media (max-width: ${breakpoints.mobile}) {
      margin-right: 10px;
    }
  }
  .fedex-bonus {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
  &.landing {
    margin-top: 20px;
    margin-bottom: 20px;
    .score {
      .score-container:not(.par) {
        &:before {
          width: 24px;
          height: 24px;
        }
      }
    }
    .hole {
      min-width: 27px;
      font-size: 13px;
      &.label {
        width: 50px;
        text-align: center !important;
      }
      &.out-in {
        max-width: 27px;
      }
      &.total {
        flex: 0 0 50px;
      }
    }
  }

  .score {
    .score-container:not(.par) {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      height: 39px;
      max-width: 39px;
      position: relative;

      &:before {
        content: "";
        width: 30px;
        height: 30px;
        border: 1px solid ${palette.blue};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @media (max-width: ${breakpoints.mobile}) {
        height: 25px;
        &:before {
          width: 22px;
          height: 22px;
        }
      }
      &.birdie {
        &:before {
          border-radius: 50%;
        }
      }
      &.double-bogey {
        &:before {
          box-shadow: 0 0 0 1px ${palette.white}, 0 0 0 2px ${palette.blue};
        }
      }
      &.eagle {
        &:before {
          border-radius: 50%;
          box-shadow: 0 0 0 1px ${palette.white}, 0 0 0 2px ${palette.blue};
        }
      }
    }
    &.bottom-row,
    &.score-container {
      display: flex;
      flex-direction: row;
      height: 45px;
      align-items: center;
      font-size: 12px;
      .fedex-logo {
        position: relative;
        width: 107px;
        padding-bottom: 34%;
        object-fit: contain;
        img {
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
      }
      .demo {
        max-height: 22px;
        flex: 0 0 22px;
        margin: 0 12px 0 22px !important;
      }
    }
  }
`;
