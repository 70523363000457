import styled from "styled-components";
import { palette, pxToRem } from "../../../styles/styleUtils";
import { breakpoints } from "../../../hooks/useBreakPoints";

export const SwapContainer = styled.div`
  display: flex;
  margin-top: 32px;
  position: relative;
  justify-content: space-between;
  flex: 1 1 auto;
  .header-name {
    text-align: center;
    margin-top: 5px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(14)};
      max-width: 42px;
      margin: 0 auto;
    }
  }
  .close-button {
    width: 100%;
    background: #b8b8b8;
    color: #fff;
    font-weight: 700;
    p {
      margin: 0;
      padding: 6px;
      position: relative;
      display: inline-block;
      &:before,
      &:after {
        content: "";
        height: 50%;
        width: 2px;
        background: #fff;
        display: block;
        position: absolute;
        top: 44%;
      }
      &:before {
        left: -1px;
        transform: rotate(-45deg) translateY(-50%);
      }
      &:after {
        left: -14px;
        transform: rotate(45deg) translateY(-50%);
      }
    }
  }

  img {
    border-radius: 50%;
  }
  &.expert-roster {
    max-width: 260px;
    margin-top: 42px;
    @media (max-width: ${breakpoints.mobile}) {
      max-width: 155px;
    }
  }
  &.expert-bench {
    max-width: 131px;
    margin-top: 42px;
    @media (max-width: ${breakpoints.mobile}) {
      max-width: 75px;
    }
  }
  &.bench {
    max-width: 317px;
    margin-left: 63px;
    @media (max-width: ${breakpoints.laptop}) {
      margin-left: 30px;
    }
    @media (max-width: ${breakpoints.rosterMobile}) {
      &:not(.modal) {
        max-width: 100%;
        margin-left: 0;
        margin-top: 35px;
      }
    }
  }
  &.roster {
    @media (max-width: ${breakpoints.rosterMobile}) {
      flex-direction: column;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -32px;
    border-color: ${palette.borderGrey};
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    width: 99.7%;
    height: 20px;
    @media (max-width: ${breakpoints.rosterMobile}) {
      border-width: 1px 0px 0px 0px;
    }
  }
  &.expert-roster:before,
  &.expert-bench:before {
    border-width: 1px 1px 0px 1px;
  }
  .section-label {
    position: absolute;
    top: -38px;
    left: 50%;
    width: 80px;
    text-align: center;
    transform: translateX(-50%);
    font-size: 11px;
    background-color: white;
    text-transform: uppercase;
    @media (max-width: ${breakpoints.rosterMobile}) {
      font-size: 16px;
      top: -26px;
    }
  }
  &.expert-roster,
  &.expert-bench {
    .section-label {
      @media (max-width: ${breakpoints.mobile}) {
        width: 50px;
        font-size: 10px;
        top: -38px;
      }
    }
  }
`;

export const SlotContainer = styled.div`
  height: 254px;
  width: 147px;
  &.dragged-over {
    .transform-hover {
      transform: none !important;
    }
  }
  @media (max-width: ${breakpoints.rosterMobile}) {
    margin: 8px 0px;
    min-width: 100%;
    height: 109.9px;
  }
`;
