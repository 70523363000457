import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ScrolledAdContainer = styled.div`
  min-height: 90px;
  width: 100%;
`;

const ScrolledAd = ({ children }) => {
  const [seen, setSeen] = useState<boolean>(false);

  const containerRef = useRef<any>();
  const isInViewport = () => {
    const rect = containerRef?.current?.getBoundingClientRect();
    return (
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  useEffect(() => {
    const handleScroll = () => {
      if (!seen) {
        if (isInViewport()) {
          setSeen(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return <ScrolledAdContainer ref={containerRef}>{seen && children}</ScrolledAdContainer>;
};

export default ScrolledAd;
