import { useInfiniteQuery } from "react-query";
import { QUERIES } from "../../constants/Queries";
import { API_URL } from "../../settings";
import { Filter, IPlayerListResponse, ISearchPlayer, TOrder } from "../../types/interfaces/RosterTypes";

export const fetchPlayersFromAPI = ({
  pageParam,
  filter = "field",
  search = null,
  order,
  tournament_id,
  sort_by,
}: ISearchPlayer): Promise<IPlayerListResponse> => {
  const url = `
    ${API_URL}/${"static-v1"}/pgaroster/players/tournament/${tournament_id}/filter/${filter}/${
    order ? "order/" + order + "/" : ""
  }${sort_by}/page/${pageParam}.json?${search ? `&search=${search}` : ""}`;

  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

// React-query hooks

export const useInfinitePlayerList = (
  filter?: Filter | null,
  search?: string,
  order?: TOrder | null,
  tournament_id?: number | undefined,
  sort_by?: string,
) => {
  return useInfiniteQuery(
    [QUERIES.PLAYERS, order, filter],
    ({ pageParam = 1 }) => fetchPlayersFromAPI({ pageParam, filter, search, order, tournament_id, sort_by }),
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage) => (lastPage.pagination.has_next ? lastPage.pagination.page + 1 : undefined),
    },
  );
};
