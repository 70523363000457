import { useQuery, UseQueryResult } from "react-query";
import { QUERIES } from "../../constants/Queries";
import { API_URL } from "../../settings";
import { IEditorialsResponse } from "../../types/interfaces/dataTypes";

export const fetchEditorials = (): Promise<IEditorialsResponse> => {
  const url = `
  ${API_URL}/static-v1/pgaroster/editorials/page/1.json`;
  return new Promise((resolve, reject) =>
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      if (res.status === 200) {
        resolve(response);
      }
      if (response?.errors) {
        reject(new Error(response));
      } else {
        reject();
      }
    }),
  );
};

export const useEditorialsQuery = (): UseQueryResult<IEditorialsResponse> => {
  return useQuery([QUERIES.EDITORIALS], () => fetchEditorials(), {
    staleTime: 1000 * 60 * 15,
  });
};
